import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The LabelGroup component is used to add commonly used margins and wrapping for groups of Labels.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<LabelGroup>
  <Label>Default label</Label>
  <Label bg='red.4'>Label with red background</Label>
  <Label outline>Default outline label</Label>
</LabelGroup>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`LabelGroup components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`LabelGroup does not get any additional props other than the `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      